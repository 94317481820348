<template>
  <div class="section__lk-order-form container-shadow fill-white">
    <template v-if="event && participant">
      <div class="justify-content-between d-none d-md-flex d-lg-flex">
        <h2>Данные заявки</h2>
        <router-link to="/lk" class="test__footer-link">
          <span class="mr-2">Вернуться в личный кабинет</span>
          <img src="/pic/icon-arrow-circle-right.svg" />
        </router-link>
      </div>
      <div class="separator"></div>
      <div v-if="participant.status === 'participant'">
        <h1>Ваша заявка на участие в обучении отправлена на рассмотрение.</h1>
      </div>
      <div v-else-if="participant.status === 'approved'">
        <h1>Ваша заявка на участие в мероприятии одобрена!</h1>
        <h3 class="mt-2">Как только она будет принята, вам станет доступна вкладка <router-link to="/lk/seminars">Экспортные семинары</router-link> в <router-link to="/lk/seminars">Личном кабинете</router-link>.</h3>
      </div>
      <form v-else @submit.prevent="submit" novalidate>
      <div v-if="participant.status === 'rejected' && participant.event_participants.length">
        <h3>Комментарии от администратора</h3>
        <admin-comments :comments="participant.event_participants" />
        <div class="separator"></div>
      </div>
      <template v-if="!secondStep">
        <user-settings
            :show-snils="event.type === 'lecture' || event.is_one_c"
            :show-phone="true"
            :show-address="event.type !== 'seminar'"
            :event-mode="event.type !== 'lecture'"
            v-model="user" />
        <form-separator>
          <company-settings v-model="orgModel" />
        </form-separator>
        <template v-if="event.type === 'lecture' || event.is_one_c">
          <form-separator>
            <passport-settings v-model="passport" />
          </form-separator>
          <form-separator>
            <education-settings v-model="education" />
          </form-separator>
        </template>
        <lecture-order-docs v-if="event.type === 'lecture' || event.is_one_c" v-model="docs" />
        <template v-if="event.is_show_trip">
          <form-separator>
            <seminar-order-docs :is-seminar-mode="event.type === 'seminar'" :program="event.program.id" v-model="docs" />
          </form-separator>
        </template>
      </template>
      <template v-if="event.program.id === 1">
        <tutor-event-order v-model="data" />
      </template>
      <template v-if="event.program.id === 46">
        <msp-event-order v-model="data" />
      </template>
      <div class="section__lk-order-actions">
        <div><button class="link-target" type="submit"><span>{{ secondStep ? 'отправить заявку' : 'продолжить' }}</span></button></div>
        <div class="section__lk-order-draft"><a href="#" @click.prevent="draft()">Сохранить в черновик</a></div>
        <a target="_blank" href="mailto:info@exportedu.ru" class="btn btn-outline-primary section__lk-order-help"><span>Нужна помощь?</span></a>
      </div>
    </form>
    </template>
  </div>
</template>

<script>
import {TokenService} from "../../services/token.service";
import UserSettings from "@/components/profile/UserSettings.vue";
import CompanySettings from "@/components/CompanySettings.vue";
import SeminarOrderDocs from "@/components/SeminarOrderDocs.vue";
import ApiService from "../../services/api.service";
import {eventBus} from "@/main";
import AdminComments from "@/components/AdminComments.vue";
import {mapActions, mapGetters} from "vuex";
import TutorEventOrder from "@/components/TutorEventOrder.vue";
import PassportSettings from "@/components/PassportSettings.vue";
import EducationSettings from "@/components/EducationSettings.vue";
import FormSeparator from "@/components/form/FormSeparator.vue";
import LectureOrderDocs from "@/components/LectureOrderDocs.vue";
import MspEventOrder from "@/components/MspEventOrder.vue";

export default {
  name: "EventOrder",
  components: {
    MspEventOrder,
    LectureOrderDocs,
    FormSeparator,
    EducationSettings,
    PassportSettings, TutorEventOrder, AdminComments, SeminarOrderDocs, CompanySettings, UserSettings},
  data() {
    return {
      user: TokenService.getUserObj(),
      docs: {},
      data: {},
      participant: null,
      orgModel: null,
      event: this.$parent.event,
      secondStep: false,
      passport: null,
      education: TokenService.getUserObj(),
    }
  },
  methods: {
    ...mapActions(['updateOrganization']),
    async draft() {
      let res = await ApiService.post('events/'+this.$route.params.id+'/subscribes?v=2', {is_draft: true, data: this.data})
      this.participant = res.data
      await this.saveForm()
      await this.updateOrganization(this.orgModel)
      if(res.status === 200) {
        this.$toastr.s('', "Черновик успешно сохранен");
      } else {
        this.$toastr.e('', "Проверьте правильность полей");
      }
    },
    async submit() {
      let result = await this.$validator.validateAll()
      let formRes = await this.saveForm()

      console.log("docs:", this.docs);
      console.log("validDocs:", this.validDocs);
      if(result && formRes && this.validDocs) {

        if([1,46].indexOf(this.event.program.id) === -1 || this.secondStep) {
          let res = await ApiService.post('events/'+this.$route.params.id+'/subscribes?v=2', {data: this.data})
          this.participant = res.data
          this.$on('on-submit')
          if(res.status === 200) {
            this.$toastr.s('', "Заявка успешно отправлена");
            await this.$router.push('/lk/requests/offline').catch(() => {})
          } else {
            this.$toastr.e('', "Проверьте правильность заполнения полей");
          }
        } else {
          this.secondStep = true
        }
      } else {
        this.$toastr.e('', "Проверьте правильность заполнения полей");
      }
    },
    async saveForm() {
      try {

        if(this.passport) {
          this.passport = (await ApiService.put('userpassport', {
            ...this.passport
          })).data
        }

        delete this.education.sex
        let res = await ApiService.put('user', {
          ...this.user,
          ...this.education,
          region: this.user.region.id,
          institute_country: 1
        })

        if (res.status > 300) {
          return false;
        } else {
          this.user = res.data
          TokenService.setUser(this.user);
          eventBus.$emit('user:upd');
          return true;
        }
      } catch (error) {
        console.error(error)
        return false
      }
    }
  },
  async mounted() {

    this.participant = (await ApiService.get('/eventparticipants/' + this.$route.params.id)).data

    if(!this.participant) {
      this.participant = {}
    }

    if(!this.participant.data) {
      this.participant.data = {}
    }

    const partData = this.participant.data

    if(JSON.stringify(partData) !== "[]") {
      this.data = this.participant.data
    }

    this.orgModel = this.organization
  },
  computed: {
    validDocs() {
      if (!this.docs) return false;

      console.log( this.event?.is_one_c)

      if (this.event?.program?.id === 1 || this.event?.is_one_c) {
        return !!(this.docs.scan_passport && this.docs.scan_diploma && this.docs.scan_snils);
      }

      return !!this.docs?.scan_trip_education;
    },
    ...mapGetters(['organization'])
  }
}
</script>

<style scoped>

</style>