<template>
  <div class="main-menu">
    <div class="section section__menu">
      <header class="header">
        <div class="header__wrapper">
          <div class="flex-grow-1">
            <a class="header__logo" href="/">
              <svg width="297" height="96" viewBox="0 0 297 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M46.2711 51.1739C42.6599 51.1674 39.0554 50.8675 35.4933 50.2773C37.0039 52.1599 38.6317 53.9464 40.3674 55.6262C43.6588 54.8506 46.714 53.2986 49.2747 51.1012C48.3426 51.1496 47.3434 51.1799 46.2772 51.1799" fill="#3777BC"/>
                <path d="M29.017 55.2992C31.0661 55.9242 33.1977 56.2407 35.341 56.2381C36.2374 56.2392 37.1328 56.1825 38.0218 56.0685C36.245 54.2535 34.5749 52.3382 33.0198 50.332C31.8082 52.0737 30.4811 53.7331 29.0474 55.2992" fill="#002E5E"/>
                <path d="M23.4727 26.8407C25.3612 34.1066 28.7287 40.9102 33.367 46.8306C36.7042 40.762 38.86 34.1219 39.7215 27.2587C35.703 26.538 31.6144 26.2799 27.5364 26.4894C26.1778 26.556 24.8131 26.6711 23.4605 26.8286L23.4727 26.8407ZM33.0258 42.663C30.1013 38.3854 27.8305 33.7005 26.2875 28.761C26.7444 28.7246 27.2013 28.7004 27.6522 28.6762C30.8543 28.5148 34.0646 28.6486 37.2419 29.076C36.4658 33.7748 35.0474 38.3462 33.0258 42.663Z" fill="#3777BC"/>
                <path d="M15.3696 42.748C16.4305 45.5028 18.0692 48.0014 20.1767 50.0777C22.1524 51.9511 24.4515 53.4552 26.9638 54.5179C28.4638 52.889 29.8558 51.1651 31.1311 49.3568C25.5598 47.9934 20.2404 45.763 15.3696 42.748ZM26.4154 51.8768C24.696 50.9763 23.1093 49.8451 21.6998 48.5148C23.5867 49.3277 25.5169 50.0375 27.4816 50.641C27.1283 51.0772 26.781 51.4951 26.4337 51.901" fill="#ED1D24"/>
                <path d="M22.0349 26.5684C19.7258 26.8228 17.5446 27.2044 15.3696 27.586C13.9113 31.4765 13.6213 35.7044 14.5349 39.7556C19.4792 43.1293 24.9601 45.6502 30.7472 47.2125C26.4768 40.9834 23.5136 33.9621 22.0349 26.5684ZM17.0207 29.5063C18.1052 29.3185 19.2018 29.1368 20.3229 28.9793C21.5071 33.9607 23.3509 38.7639 25.8062 43.263C22.5472 41.9858 19.4312 40.3738 16.5089 38.4533C15.9709 35.4723 16.1462 32.4073 17.0207 29.5063Z" fill="#002E5E"/>
                <path d="M34.4027 48.0421C38.3249 48.8022 42.3116 49.1856 46.3076 49.187C48.1354 49.187 49.7438 49.1083 51.1146 48.9932C51.1322 48.9861 51.1519 48.9861 51.1694 48.9932C51.2121 48.9932 51.2608 49.0356 51.3035 49.084C51.8783 48.415 52.4133 47.7131 52.9058 46.9821C50.5345 40.2103 46.8034 33.9884 41.9392 28.6943C40.6784 35.5741 38.1125 42.1524 34.3784 48.0785L34.4027 48.0421ZM50.4749 46.6429C50.414 46.7277 50.347 46.8064 50.2799 46.8912C48.9883 46.9821 47.654 47.0245 46.3198 47.0245C43.5023 47.02 40.6886 46.8176 37.8999 46.4187C40.1685 42.3234 41.9202 37.9654 43.1151 33.4435C46.2232 37.4495 48.7045 41.8995 50.4749 46.6429Z" fill="#ED1D24"/>
                <path d="M28.0787 24.2302C31.5781 24.2213 35.0698 24.5561 38.5031 25.2297C34.5659 21.6522 30.1694 18.6091 25.4284 16.1797C24.6608 16.6946 23.7652 17.2034 22.9305 17.7728C22.9732 19.9935 23.1768 22.2082 23.5397 24.3998C25.0568 24.2907 26.586 24.2302 28.0482 24.2302H28.0787ZM25.4894 18.7057C27.4166 19.746 29.2839 20.8926 31.0823 22.1403C30.0771 22.0858 29.0657 22.0616 28.036 22.0616C27.1648 22.0616 26.2875 22.0616 25.398 22.1161C25.27 21.1045 25.1786 20.0444 25.1238 18.9298L25.4772 18.7117" fill="#ED1D24"/>
                <path d="M21.0113 19.4326C20.7232 19.6055 20.4625 19.8199 20.2375 20.0687C18.6426 21.6459 17.3228 23.4761 16.3322 25.4841C18.0625 25.1631 19.8537 24.9086 21.584 24.7209C21.2966 22.9698 21.1055 21.2044 21.0113 19.4326Z" fill="#3777BC"/>
                <path d="M56.4456 32.1056C55.7852 27.5713 53.6722 23.3696 50.4201 20.1237C48.3306 18.0357 45.8203 16.411 43.0542 15.3564C43.1802 18.9789 42.964 22.6051 42.4084 26.1873C47.3387 27.4901 52.0571 29.485 56.4213 32.1116L56.4456 32.1056ZM45.2658 18.8456C46.5773 19.642 47.7882 20.5918 48.8726 21.6745C50.6421 23.4194 52.0479 25.4944 53.0094 27.7805C50.3793 26.5086 47.6513 25.4472 44.8515 24.6063C45.1135 22.3832 45.2353 20.4387 45.2841 18.8456" fill="#3777BC"/>
                <path d="M56.5736 34.5278C52.9356 32.1606 48.9898 30.2979 44.8454 28.9912C48.9264 33.668 52.118 39.0437 54.2645 44.8559C55.9012 41.674 56.6959 38.13 56.5736 34.5581V34.5278ZM54.0452 38.8529C53.2172 37.191 52.3021 35.5734 51.3035 34.0069C52.3453 34.5278 53.375 35.1336 54.3924 35.7393C54.3652 36.7871 54.249 37.8306 54.0452 38.859" fill="#002E5E"/>
                <path d="M41.001 14.7202C36.6747 13.5017 32.0686 13.7013 27.8654 15.2897C32.5234 17.7706 36.8145 20.8792 40.6171 24.5274C41.0017 21.2733 41.1301 17.9942 41.001 14.7202ZM38.8016 19.9903C37.1468 18.6296 35.4202 17.3577 33.629 16.1801C34.1834 16.1317 34.7378 16.1074 35.2983 16.1074C36.4885 16.1077 37.6758 16.2213 38.8442 16.4466C38.8442 17.5431 38.8442 18.7182 38.8016 19.9903Z" fill="#002E5E"/>
                <path d="M58.2734 67.1055L43.4989 74.4533L36.1147 67.1115L58.2734 67.1055Z" fill="#3777BC"/>
                <path d="M58.2734 67.1055L50.8892 81.8011L43.499 74.4533L58.2734 67.1055Z" fill="#ED1D24"/>
                <path d="M65.6637 67.1357H68.6003C70.3428 67.1357 71.4394 68.1231 71.4394 69.6375C71.4394 71.3033 70.0991 72.1696 68.4541 72.1696H66.9736V74.5563H65.6637V67.1357ZM68.4967 70.9944C69.4898 70.9944 70.1052 70.4432 70.1052 69.6496C70.1052 68.8561 69.4959 68.323 68.4967 68.323H66.9736V70.9944H68.4967Z" fill="#002E5E"/>
                <path d="M72.9626 70.8735C72.9541 70.1033 73.1763 69.348 73.6008 68.7036C74.0252 68.0592 74.6329 67.555 75.3464 67.2549C76.06 66.9549 76.8471 66.8726 77.6078 67.0186C78.3685 67.1646 79.0683 67.5322 79.6183 68.0748C80.1683 68.6173 80.5435 69.3102 80.6963 70.0653C80.8491 70.8205 80.7725 71.6037 80.4764 72.3155C80.1802 73.0273 79.6778 73.6354 79.0331 74.0625C78.3884 74.4896 77.6304 74.7164 76.8557 74.714C76.3446 74.7229 75.8368 74.6299 75.3624 74.4405C74.888 74.251 74.4566 73.9689 74.0937 73.6109C73.7308 73.2529 73.4437 72.8262 73.2494 72.3561C73.0551 71.8859 72.9576 71.3818 72.9626 70.8735ZM79.3902 70.8735C79.4033 70.5337 79.3475 70.1947 79.2263 69.8767C79.105 69.5587 78.9207 69.2681 78.6842 69.0223C78.4478 68.7765 78.1641 68.5805 77.85 68.4458C77.5359 68.3112 77.1978 68.2407 76.8557 68.2385C76.5134 68.238 76.1746 68.3069 75.86 68.4408C75.5453 68.5747 75.2613 68.7709 75.0254 69.0174C74.7894 69.2639 74.6064 69.5556 74.4874 69.8747C74.3684 70.1938 74.316 70.5336 74.3334 70.8735C74.3203 71.2137 74.3761 71.5529 74.4973 71.8713C74.6185 72.1896 74.8027 72.4805 75.0391 72.7268C75.2754 72.9731 75.559 73.1697 75.8732 73.305C76.1873 73.4403 76.5256 73.5116 76.8679 73.5146C77.2105 73.5142 77.5494 73.4446 77.8641 73.31C78.1788 73.1755 78.4627 72.9787 78.6986 72.7317C78.9345 72.4847 79.1174 72.1927 79.2363 71.8732C79.3552 71.5538 79.4076 71.2137 79.3902 70.8735Z" fill="#002E5E"/>
                <path d="M82.534 70.8732C82.5232 70.3672 82.6154 69.8643 82.805 69.3946C82.9946 68.925 83.2778 68.4983 83.6374 68.1401C83.997 67.782 84.4258 67.4998 84.8978 67.3106C85.3699 67.1213 85.8756 67.0288 86.3845 67.0387C86.9246 67.019 87.4629 67.1111 87.9653 67.3093C88.4677 67.5074 88.9232 67.8072 89.3028 68.1897L88.462 69.1589C88.1922 68.8757 87.8686 68.6485 87.5099 68.4904C87.1512 68.3324 86.7646 68.2466 86.3723 68.2381C85.7066 68.2931 85.086 68.5949 84.6337 69.0835C84.1813 69.5722 83.9303 70.2121 83.9303 70.8762C83.9303 71.5403 84.1813 72.1802 84.6337 72.6689C85.086 73.1575 85.7066 73.4593 86.3723 73.5143C86.7773 73.5098 87.177 73.4219 87.5461 73.2561C87.9152 73.0903 88.2458 72.8503 88.5169 72.5511L89.3577 73.3992C88.981 73.8307 88.5118 74.1728 87.9846 74.4003C87.4574 74.6278 86.8857 74.7349 86.3114 74.7137C85.8076 74.7154 85.3086 74.6167 84.8438 74.4233C84.3791 74.2298 83.9582 73.9456 83.606 73.5875C83.2537 73.2294 82.9774 72.8046 82.7932 72.3384C82.6091 71.8721 82.5209 71.3739 82.534 70.8732Z" fill="#002E5E"/>
                <path d="M90.9112 70.873C90.9005 70.367 90.9927 69.8641 91.1823 69.3945C91.3719 68.9248 91.655 68.4981 92.0147 68.14C92.3743 67.7818 92.803 67.4996 93.2751 67.3104C93.7472 67.1211 94.2528 67.0287 94.7617 67.0386C95.3018 67.0196 95.8399 67.112 96.3421 67.3101C96.8444 67.5082 97.3 67.8076 97.6801 68.1895L96.8393 69.1587C96.5695 68.8755 96.2459 68.6483 95.8872 68.4902C95.5285 68.3322 95.1418 68.2464 94.7496 68.238C94.0839 68.2929 93.4633 68.5947 93.011 69.0834C92.5586 69.572 92.3075 70.2119 92.3075 70.876C92.3075 71.5401 92.5586 72.18 93.011 72.6687C93.4633 73.1573 94.0839 73.4591 94.7496 73.5141C95.1538 73.5036 95.5515 73.41 95.9175 73.239C96.2834 73.068 96.6098 72.8234 96.8759 72.5206L97.7166 73.3687C97.3394 73.7996 96.8701 74.1412 96.3431 74.3686C95.816 74.5961 95.2445 74.7035 94.6704 74.6832C94.1707 74.6825 93.6762 74.5829 93.2157 74.3902C92.7552 74.1976 92.3378 73.9157 91.9879 73.5611C91.6381 73.2065 91.3627 72.7862 91.1778 72.3247C90.993 71.8631 90.9024 71.3697 90.9112 70.873Z" fill="#002E5E"/>
                <path d="M99.6236 67.1357H100.909V72.5512L104.687 67.1357H105.911V74.5563H104.62V69.1469L100.86 74.5563H99.6236V67.1357Z" fill="#002E5E"/>
                <path d="M108.354 67.136H109.67V72.5514L113.448 67.136H114.666V74.5565H113.326V69.1471L109.561 74.5565H108.342L108.354 67.136ZM109.853 65.0704L110.651 64.9189C110.69 65.1247 110.799 65.3106 110.961 65.4444C111.123 65.5781 111.327 65.6514 111.538 65.6514C111.748 65.6514 111.952 65.5781 112.114 65.4444C112.276 65.3106 112.386 65.1247 112.424 64.9189L113.21 65.0704C113.176 65.4833 112.984 65.8674 112.673 66.1435C112.362 66.4196 111.957 66.5667 111.541 66.5545C111.123 66.5656 110.716 66.4186 110.403 66.1431C110.089 65.8676 109.893 65.4843 109.853 65.0704Z" fill="#002E5E"/>
                <path d="M116.75 70.8732C116.739 70.3672 116.831 69.8643 117.021 69.3946C117.21 68.925 117.494 68.4983 117.853 68.1401C118.213 67.782 118.642 67.4998 119.114 67.3106C119.586 67.1213 120.091 67.0288 120.6 67.0387C121.14 67.019 121.679 67.1111 122.181 67.3093C122.684 67.5074 123.139 67.8072 123.519 68.1897L122.678 69.1589C122.408 68.8757 122.084 68.6485 121.726 68.4904C121.367 68.3324 120.98 68.2466 120.588 68.2381C119.922 68.2931 119.302 68.5949 118.85 69.0835C118.397 69.5722 118.146 70.2121 118.146 70.8762C118.146 71.5403 118.397 72.1802 118.85 72.6689C119.302 73.1575 119.922 73.4593 120.588 73.5143C120.993 73.5098 121.393 73.4219 121.762 73.2561C122.131 73.0903 122.462 72.8503 122.733 72.5511L123.573 73.3992C123.196 73.83 122.727 74.1717 122.2 74.3991C121.673 74.6265 121.101 74.734 120.527 74.7137C120.023 74.7154 119.524 74.6167 119.06 74.4233C118.595 74.2298 118.174 73.9456 117.822 73.5875C117.47 73.2294 117.193 72.8046 117.009 72.3384C116.825 71.8721 116.737 71.3739 116.75 70.8732Z" fill="#002E5E"/>
                <path d="M125.456 67.1357H126.772V70.8672L130.318 67.1357H131.926L128.825 70.3281L132.066 74.5563H130.482L127.936 71.2125L126.772 72.3998V74.5563H125.456V67.1357Z" fill="#002E5E"/>
                <path d="M133.699 67.1357H134.991V72.5512L138.762 67.1357H139.987V74.5563H138.695V69.1469L134.936 74.5563H133.699V67.1357Z" fill="#002E5E"/>
                <path d="M142.43 67.136H143.715V72.5514L147.493 67.136H148.711V74.5565H147.444V69.1471L143.679 74.5565H142.46L142.43 67.136ZM143.929 65.0704L144.733 64.9189C144.767 65.1253 144.875 65.3124 145.038 65.445C145.2 65.5777 145.406 65.647 145.616 65.6398C145.826 65.6444 146.03 65.5742 146.192 65.442C146.354 65.3098 146.463 65.1242 146.5 64.9189L147.286 65.0704C147.251 65.4833 147.059 65.8674 146.749 66.1435C146.438 66.4196 146.033 66.5667 145.616 66.5545C145.198 66.5656 144.792 66.4186 144.478 66.1431C144.165 65.8676 143.969 65.4843 143.929 65.0704Z" fill="#002E5E"/>
                <path d="M154.249 73.4117L155.09 72.5515C155.369 72.8524 155.707 73.0932 156.083 73.2589C156.459 73.4246 156.866 73.5116 157.277 73.5146C157.877 73.5188 158.457 73.3029 158.907 72.9084C159.356 72.5139 159.644 71.9684 159.714 71.3763H156.364V70.2678H159.702C159.618 69.6867 159.325 69.1557 158.876 68.7736C158.428 68.3914 157.856 68.1841 157.265 68.19C156.477 68.1996 155.722 68.5097 155.157 69.0563L154.408 68.1658C154.779 67.7879 155.224 67.4897 155.715 67.2896C156.207 67.0896 156.734 66.9919 157.265 67.0028C158.263 67.0345 159.21 67.4491 159.907 68.1596C160.604 68.8702 160.997 69.8215 161.004 70.814C161.011 71.8066 160.632 72.7633 159.945 73.4836C159.258 74.2039 158.317 74.6319 157.32 74.6777C156.749 74.7016 156.181 74.5999 155.654 74.3801C155.127 74.1602 154.656 73.8274 154.274 73.4056" fill="#002E5E"/>
                <path d="M163.206 67.1357H164.509V70.8672L168.061 67.1357H169.67L166.569 70.3281L169.816 74.5563H168.22L165.673 71.2125L164.509 72.3998V74.5563H163.206V67.1357Z" fill="#002E5E"/>
                <path d="M170.791 70.873C170.78 70.367 170.872 69.8641 171.062 69.3945C171.251 68.9248 171.535 68.4981 171.894 68.14C172.254 67.7818 172.683 67.4996 173.155 67.3104C173.627 67.1211 174.132 67.0287 174.641 67.0386C175.181 67.0196 175.719 67.112 176.222 67.3101C176.724 67.5082 177.179 67.8076 177.56 68.1895L176.719 69.1587C176.449 68.8755 176.125 68.6483 175.767 68.4902C175.408 68.3322 175.021 68.2464 174.629 68.238C173.963 68.2929 173.343 68.5947 172.89 69.0834C172.438 69.572 172.187 70.2119 172.187 70.876C172.187 71.5402 172.438 72.18 172.89 72.6687C173.343 73.1574 173.963 73.4591 174.629 73.5141C175.034 73.5096 175.434 73.4217 175.803 73.2559C176.172 73.0902 176.503 72.8501 176.774 72.5509L177.614 73.399C177.237 73.8299 176.768 74.1715 176.241 74.3989C175.714 74.6264 175.142 74.7338 174.568 74.7135C174.064 74.7153 173.565 74.6165 173.101 74.4231C172.636 74.2296 172.215 73.9454 171.863 73.5873C171.51 73.2292 171.234 72.8044 171.05 72.3382C170.866 71.872 170.778 71.3737 170.791 70.873Z" fill="#002E5E"/>
                <path d="M179.503 67.1357H185.584V74.5563H184.274V68.3109H180.813V74.5563H179.503V67.1357Z" fill="#002E5E"/>
                <path d="M187.685 70.8735C187.677 70.1033 187.899 69.348 188.324 68.7036C188.748 68.0592 189.356 67.555 190.069 67.2549C190.783 66.9549 191.57 66.8726 192.331 67.0186C193.091 67.1646 193.791 67.5322 194.341 68.0748C194.891 68.6173 195.266 69.3102 195.419 70.0653C195.572 70.8205 195.495 71.6037 195.199 72.3155C194.903 73.0273 194.401 73.6354 193.756 74.0625C193.111 74.4896 192.353 74.7164 191.579 74.714C191.067 74.7229 190.56 74.6299 190.085 74.4405C189.611 74.251 189.179 73.9689 188.817 73.6109C188.454 73.2529 188.167 72.8262 187.972 72.3561C187.778 71.8859 187.68 71.3818 187.685 70.8735ZM194.113 70.8735C194.126 70.5337 194.07 70.1947 193.949 69.8767C193.828 69.5587 193.644 69.2681 193.407 69.0223C193.171 68.7765 192.887 68.5805 192.573 68.4458C192.259 68.3112 191.921 68.2407 191.579 68.2385C191.237 68.2389 190.899 68.3083 190.585 68.4426C190.271 68.5769 189.988 68.7732 189.752 69.0196C189.517 69.266 189.334 69.5574 189.216 69.8761C189.097 70.1948 189.045 70.5341 189.062 70.8735C189.049 71.2137 189.105 71.5529 189.226 71.8713C189.347 72.1896 189.532 72.4805 189.768 72.7268C190.004 72.9731 190.288 73.1697 190.602 73.305C190.916 73.4403 191.255 73.5116 191.597 73.5146C191.939 73.5134 192.277 73.4432 192.591 73.3083C192.905 73.1733 193.188 72.9764 193.424 72.7295C193.659 72.4826 193.841 72.1908 193.96 71.8718C194.078 71.5528 194.13 71.2132 194.113 70.8735Z" fill="#002E5E"/>
                <path d="M197.592 67.1357H200.529C202.271 67.1357 203.368 68.1231 203.368 69.6375C203.368 71.3033 202.021 72.1696 200.382 72.1696H198.902V74.5563H197.592V67.1357ZM200.449 70.9944C201.436 70.9944 202.058 70.4432 202.058 69.6496C202.058 68.8561 201.449 68.323 200.449 68.323H198.926V70.9944H200.449Z" fill="#002E5E"/>
                <path d="M206.84 68.3412H204.477V67.1357H210.526V68.3412H208.163V74.5563H206.84V68.3412Z" fill="#002E5E"/>
                <path d="M212.427 67.1357H213.737V70.219H217.301V67.1357H218.611V74.5563H217.301V71.4305H213.737V74.5563H212.427V67.1357Z" fill="#002E5E"/>
                <path d="M221.036 67.1357H222.334V69.8071H224.052C225.727 69.8071 226.861 70.631 226.861 72.1272C226.861 73.6234 225.77 74.5502 224.046 74.5502H221.036V67.1357ZM224.028 73.3871C224.996 73.3871 225.557 72.951 225.557 72.1151C225.557 71.2791 224.996 70.9399 224.003 70.9399H222.334V73.3629L224.028 73.3871ZM227.744 67.1357H229.048V74.5563H227.744V67.1357Z" fill="#002E5E"/>
                <path d="M231.473 67.136H232.74V72.5514L236.511 67.136H237.73V74.5565H236.444V69.1471L232.74 74.5565H231.521L231.473 67.136ZM232.978 65.0704L233.776 64.9189C233.81 65.1253 233.918 65.3124 234.081 65.445C234.243 65.5777 234.449 65.647 234.659 65.6398C234.869 65.6446 235.074 65.5747 235.238 65.4425C235.401 65.3104 235.511 65.1247 235.549 64.9189L236.335 65.0704C236.3 65.4843 236.107 65.8693 235.795 66.1456C235.483 66.4219 235.077 66.5683 234.659 66.5545C234.242 66.5655 233.836 66.4183 233.523 66.1427C233.211 65.8671 233.016 65.4837 232.978 65.0704Z" fill="#002E5E"/>
                <path d="M249.683 74.5562H243.914V67.1357H245.224V73.375H248.721V67.1357H250.024V73.375H250.951L250.768 76.1433H249.683V74.5562Z" fill="#002E5E"/>
                <path d="M252.632 67.1357H258.164V68.2988H253.948V70.2312H257.683V71.3942H253.948V73.3871H258.219V74.5563H252.632V67.1357Z" fill="#002E5E"/>
                <path d="M260.284 67.1357H261.6V70.219H265.158V67.1357H266.468V74.5563H265.158V71.4305H261.6V74.5563H260.284V67.1357Z" fill="#002E5E"/>
                <path d="M270.733 68.3412H268.369V67.1357H274.419V68.3412H272.055V74.5563H270.733V68.3412Z" fill="#002E5E"/>
                <path d="M276.314 67.1357H279.263C280.999 67.1357 282.096 68.1231 282.096 69.6375C282.096 71.3033 280.749 72.1696 279.11 72.1696H277.63V74.5563H276.314V67.1357ZM279.153 70.9944C280.14 70.9944 280.761 70.4432 280.761 69.6496C280.761 68.8561 280.152 68.323 279.153 68.323H277.63V70.9944H279.153Z" fill="#002E5E"/>
                <path d="M65.4504 27.623H68.8196V38.7992H72.8102V27.623H76.1794V38.7992H80.164V27.623H83.5331V41.8462H65.4504V27.623Z" fill="#002E5E"/>
                <path d="M86.22 31.1904H89.0896V35.3944H90.1192L93.3544 31.1904H96.9124L92.7573 36.194L97.1439 41.8457H93.5432L90.369 37.6054H89.0896V41.8457H86.22V31.1904Z" fill="#002E5E"/>
                <path d="M103.383 31.0146C106.941 31.0146 109.475 33.1469 109.475 36.5391C109.475 39.9314 106.916 42.0454 103.383 42.0454C99.849 42.0454 97.3328 39.9132 97.3328 36.5391C97.3328 33.1651 99.9282 31.0146 103.383 31.0146ZM103.383 39.4952C105.119 39.4952 106.204 38.5018 106.204 36.527C106.204 34.5523 105.04 33.5406 103.383 33.5406C101.89 33.5406 100.604 34.5159 100.604 36.527C100.604 38.3443 101.75 39.4952 103.383 39.4952Z" fill="#002E5E"/>
                <path d="M118.2 33.7164H114.91V34.1465C114.91 39.2227 113.643 42.0213 110.803 42.0213C110.453 42.0215 110.102 41.9951 109.755 41.9425V39.2045C111.248 39.3438 112.071 38.7381 112.071 33.6134V31.2812H121.246V41.8456H118.2V33.7164Z" fill="#002E5E"/>
                <path d="M130.891 40.5856H130.787C130.416 41.0617 129.933 41.4403 129.381 41.6884C128.829 41.9365 128.224 42.0466 127.619 42.0091C124.963 42.0091 122.745 40.1191 122.745 36.5573C122.745 33.2862 125.091 31.0328 128.058 31.0328C128.563 30.9921 129.071 31.0615 129.547 31.2362C130.023 31.4109 130.454 31.6868 130.811 32.0444H130.915L130.811 31.2569H133.858V41.8455H130.811L130.891 40.5856ZM130.787 38.2655V34.528C130.541 34.2381 130.229 34.0098 129.878 33.8614C129.527 33.713 129.145 33.6488 128.764 33.6739C128.387 33.6526 128.01 33.7121 127.657 33.8485C127.305 33.9848 126.987 34.1948 126.723 34.4642C126.46 34.7335 126.258 35.0561 126.131 35.4099C126.003 35.7637 125.954 36.1405 125.986 36.5149C125.986 38.2837 127.113 39.3377 128.667 39.3377C129.085 39.3514 129.5 39.2577 129.871 39.0656C130.243 38.8736 130.558 38.5897 130.787 38.2413" fill="#002E5E"/>
                <path d="M144.075 35.443H148.303C148.059 34.0437 146.707 33.4924 145.403 33.4924C144.801 33.4874 144.205 33.604 143.65 33.8352C143.095 34.0663 142.593 34.4072 142.174 34.8372L140.663 33.02C141.317 32.357 142.104 31.8389 142.974 31.4996C143.843 31.1603 144.775 31.0075 145.708 31.0512C149.18 31.0512 151.453 33.1653 151.453 36.5333C151.453 39.6469 149.552 41.9851 145.708 41.9851C144.735 42.0338 143.765 41.8654 142.866 41.4923C141.968 41.1191 141.165 40.5506 140.517 39.8286L142.211 38.1628C142.615 38.6165 143.114 38.9768 143.672 39.2185C144.231 39.4602 144.836 39.5775 145.446 39.5621C147.054 39.5621 148.181 38.8291 148.364 37.4722H144.099L144.075 35.443Z" fill="#002E5E"/>
                <path d="M153.25 31.1904H156.12V35.3944H157.156L160.391 31.1904H163.949L159.794 36.194L164.174 41.8457H160.58L157.405 37.6054H156.12V41.8457H153.25V31.1904Z" fill="#002E5E"/>
                <path d="M174.958 40.2409C174.366 40.8572 173.646 41.3387 172.849 41.6524C172.052 41.9661 171.196 42.1045 170.34 42.0581C167.068 42.0581 164.369 40.0289 164.369 36.5518C164.369 33.2202 166.861 31.0273 170.194 31.0273C172.686 31.0273 174.038 31.8391 174.794 32.5902L173.38 34.5831C172.558 33.9811 171.562 33.6601 170.541 33.6684C170.151 33.6342 169.759 33.6852 169.392 33.818C169.024 33.9508 168.69 34.1621 168.414 34.437C168.137 34.7119 167.925 35.0437 167.791 35.4091C167.658 35.7745 167.606 36.1646 167.641 36.5518C167.632 36.9446 167.706 37.3348 167.858 37.6974C168.01 38.0599 168.237 38.3867 168.524 38.6567C168.811 38.9267 169.152 39.1338 169.525 39.2648C169.897 39.3958 170.293 39.4476 170.687 39.417C171.735 39.4467 172.754 39.0685 173.526 38.363L174.958 40.2409Z" fill="#002E5E"/>
                <path d="M183.908 33.7164H179.497V41.8456H176.426V31.2812H186.973V41.8456H183.908V33.7164Z" fill="#002E5E"/>
                <path d="M194.899 31.0146C198.457 31.0146 200.992 33.1469 200.992 36.5391C200.992 39.9314 198.439 42.0454 194.899 42.0454C191.451 42.0454 188.855 39.9132 188.855 36.5391C188.855 33.1651 191.451 31.0146 194.899 31.0146ZM194.899 39.4952C196.642 39.4952 197.72 38.5018 197.72 36.527C197.72 34.5523 196.556 33.5406 194.899 33.5406C193.406 33.5406 192.121 34.5159 192.121 36.527C192.121 38.3443 193.266 39.4952 194.899 39.4952Z" fill="#002E5E"/>
                <path d="M202.886 31.2809H205.933V31.705C205.937 31.9779 205.908 32.2504 205.847 32.5167H205.933C206.292 32.0361 206.766 31.6514 207.311 31.3967C207.856 31.142 208.457 31.0252 209.058 31.0568C212.348 31.0568 214.042 33.2678 214.042 36.3996C214.042 39.5313 212.104 42.0089 208.589 42.0089C207.654 42.0709 206.724 41.8354 205.933 41.3365V45.9463H202.886V31.2809ZM205.933 34.7519V38.7136C206.519 39.2253 207.28 39.4942 208.059 39.4647C209.71 39.4647 210.776 38.5924 210.776 36.4359C210.776 34.5823 209.771 33.6918 208.284 33.6918C207.833 33.6473 207.378 33.7218 206.965 33.9079C206.552 34.094 206.196 34.3852 205.933 34.7519Z" fill="#002E5E"/>
                <path d="M214.377 31.2812H224.801V33.9223H221.103V41.8456H218.039V33.9223H214.377V31.2812Z" fill="#002E5E"/>
                <path d="M232.935 40.5858H232.831C232.46 41.0619 231.977 41.4405 231.425 41.6886C230.873 41.9367 230.268 42.0468 229.663 42.0093C227.007 42.0093 224.759 40.1194 224.759 36.5575C224.759 33.2865 227.11 31.0331 230.071 31.0331C230.578 30.9918 231.087 31.061 231.564 31.2357C232.04 31.4105 232.473 31.6865 232.831 32.0447H232.935L232.831 31.2572H235.878V41.8458H232.831L232.935 40.5858ZM232.831 38.2658V34.5283C232.585 34.2383 232.274 34.01 231.922 33.8616C231.571 33.7133 231.189 33.6491 230.809 33.6741C230.431 33.6528 230.054 33.7124 229.702 33.8487C229.35 33.9851 229.031 34.195 228.768 34.4644C228.504 34.7338 228.302 35.0563 228.175 35.4101C228.048 35.7639 227.998 36.1408 228.03 36.5151C228.03 38.2839 229.158 39.338 230.705 39.338C231.124 39.3527 231.54 39.2595 231.913 39.0674C232.286 38.8753 232.602 38.5908 232.831 38.2415" fill="#002E5E"/>
                <path d="M242.902 41.8335V27.6225C243.7 27.6225 246.168 27.5801 247.271 27.5801C251.621 27.5801 253.046 29.8335 253.046 32.2928C253.046 34.7522 251.066 37.0601 247.758 37.0601C247.227 37.0665 246.697 37.0463 246.168 36.9996V41.8456L242.902 41.8335ZM246.144 30.6694V33.9223C246.639 33.9848 247.137 34.0171 247.636 34.0193C248.495 34.0193 249.555 33.6558 249.555 32.2747C249.555 31.0208 248.739 30.6331 247.575 30.6331C247.143 30.6331 246.82 30.6331 246.144 30.6755" fill="#002E5E"/>
                <path d="M257.189 33.0618H262.807C262.197 31.3354 260.516 30.4207 258.353 30.4207C257.245 30.4357 256.171 30.7982 255.282 31.4565L254.033 29.0335C255.501 27.9001 257.325 27.3187 259.182 27.3919C263.325 27.3919 266.389 30.3116 266.389 34.7397C266.389 39.3071 263.428 42.0875 258.755 42.0875C256.69 42.0875 254.789 41.3969 253.936 40.6034L255.282 37.8533C256.238 38.6154 257.427 39.03 258.652 39.0285C260.546 39.0285 262.362 38.1986 262.916 36.1087H257.177L257.189 33.0618Z" fill="#002E5E"/>
                <path d="M269.009 27.623H272.275V38.7871H277.179V27.623H280.445V38.7871H282.382V44.2692H279.318V41.8341H269.009V27.623Z" fill="#002E5E"/>
              </svg>
            </a>
          </div>

          <nav class="header__navigation-menu">
            <ul>
              <li><a href="mailto:info@exportedu.ru" class="header__navigation-menu-email">info@exportedu.ru</a></li>
              <li><a href="tel:info@exportedu.ru" class="header__navigation-menu-phone">+7 (495) 937-4742</a></li>
              <li><a href="#" class="header__navigation-menu-location">123610, Москва, Краснопресненская наб. 12, подъезд
                9</a></li>
            </ul>
          </nav>

          <div class="header__user">
            <ul>
              <template v-if="user">
                <notification />
                <li class="header__user-authorized header__user-authorized_online">
                  <span :style="{backgroundImage: 'url(' + (user.avatar ? user.avatar['500x'] : '/pic/user-pic.jpeg') + ')'}"></span>
                  <router-link :to="lkPath">Мой кабинет</router-link>
                </li>
                <li class="header__user-registration"><a href="#" @click.prevent="logout">Выход</a></li>
              </template>
              <template v-else>
                <li class="header__user-login"><a href="/sign-in">Войти</a></li>
                <li class="header__user-registration"><a href="/sign-up">Регистрация</a></li>
              </template>
            </ul>
          </div>
        </div>

      </header>
      <div class="section__wrapper">
        <div class="section__menu-content">
          <div class="row">
            <div class="col-md-7 section__menu-menu">
              <ul class="mb-4 mb-md-6">
                <li><a href="#" data-menu="submenu-1" class="active">Бизнесу</a></li>
                <li><a href="#" data-menu="submenu-2">Региональным командам</a></li>
                <li><a href="/highschool">Программы с ВУЗами</a></li>
                <li><a href="/tutors" data-menu="submenu-1">Тренерам и наставникам</a></li>
                <li class="mb-2 mb-md-6"><a href="#" data-menu="submenu-4">Центры поддержки экспорта</a></li>
                <li><a href="#" data-menu="submenu-3">О школе экспорта</a></li>
                <li><a href="/calendar">Календарь обучения</a></li>
                <li><a href="/news">Новости</a></li>
                <li><a href="/documents">Антикризис</a></li>
                <li><a href="/sveden">Сведения об образовательной организации</a></li>
                <li class="placeholder"></li>
              </ul>
            </div>
            <div class="col-md-5 section__menu-submenu">
              <div data-menu="submenu-1" class="submenu active">
                <div class="title-with-icon title-with-icon_menu mb-2 mb-md-4">
                  <div class="title-with-icon__icon title-with-icon__icon_programms"></div>
                  <div class="title-with-icon__title">
                    Базовые программы
                  </div>
                </div>
                <div class=" section__menu-submenu-secondary">
                  <ul>
                    <li><a href="/education#export">Экспортные семинары</a></li>
                    <li><a href="/education#online">Дистанционные курсы</a></li>
                    <li><a href="/documents">Учебные пособия</a></li>
                  </ul>
                </div>
                <div class="title-with-icon mb-2 mb-md-4">
                  <div class="title-with-icon__icon title-with-icon__icon_smallbusiness"></div>
                  <div class="title-with-icon__title">Для малого и среднего бизнеса</div>
                </div>
                <div class=" section__menu-submenu-secondary">
                  <ul>
                    <li><a href="/acceleration#export">Акселерация. Экспортный форсаж</a></li>
                    <li><a href="/acceleration#msp">Акселерация. Экспортный Мини-МВА</a></li>
                  </ul>
                </div>
                <div class="title-with-icon mb-2 mb-md-4">
                  <div class="title-with-icon__icon title-with-icon__icon_bigbusiness"></div>
                  <div class="title-with-icon__title">Для крупного бизнеса</div>
                </div>
                <div class=" section__menu-submenu-secondary">
                  <ul>
                    <li><a href="/acceleration#accelerator">Акселерация. Экспортный рост</a></li>
                    <li><a href="/education#corporate">Корпоративные программы</a></li>
                  </ul>
                </div>
                <div class="title-with-icon mb-2 mb-md-4">
                  <div class="title-with-icon__icon title-with-icon__icon_how-learn"></div>
                  <div class="title-with-icon__title"><a class="crisis" href="/mentoring">Акселерация. Экспортное наставничество</a></div>
                </div>
                <div class="title-with-icon mb-2 mb-md-4">
                  <div class="title-with-icon__icon title-with-icon__icon_kkt"></div>
                  <div class="title-with-icon__title"><a href="/kkt">Кросс-культурная трансформация</a></div>
                </div>
              </div>
              <div data-menu="submenu-2" class="submenu">
                <div class="title-with-icon mb-2 mb-md-4">
                  <div class="title-with-icon__icon title-with-icon__icon_partners"></div>
                  <div class="title-with-icon__title">Региональным командам</div>
                </div>
                <div class="section__menu-submenu-secondary">
                  <ul>
                    <li><a href="/teams#basic">Основные сведения</a></li>
                    <li><a href="/teams#online">Организация обучения</a></li>
                    <li><a href="/teams#select">Порядок отбора субъектов РФ</a></li>
                    <li><a href="/teams#graduates">Выпускники программы</a></li>
                  </ul>
                </div>
              </div>
              <div data-menu="submenu-3" class="submenu">
                <div class="title-with-icon mb-2 mb-md-4">
                  <div class="title-with-icon__icon title-with-icon__icon_about-school"></div>
                  <div class="title-with-icon__title">О школе экспорта</div>
                </div>
                <div class=" section__menu-submenu-secondary">
                  <ul>
                    <li><a href="/about">Основные сведения</a></li>
                    <li><a href="/howto">Как учиться?</a></li>
                    <li><a href="/faq">FAQ</a></li>
                    <li><a href="/partners">Партнеры</a></li>
                  </ul>
                </div>
              </div>
              <div data-menu="submenu-4" class="submenu">
                <div class=" section__menu-submenu-secondary">
                  <ul>
                    <li><a href="#">Раздел в разработке</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section__menu-footer">
          <div class="d-flex justify-content-between align-items-center">
            <div>{{ new Date().getFullYear() }}, Школа экспорта Российского экспортного центра</div>
            <div class="btn-socials">
              <a class="container-shadow btn-social btn-social__vk" href="http://vk.com/exportedu"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import {TokenService} from "../../services/token.service";
import Notification from "@/components/Notification.vue";
import lkPathMixin from "@/mixins/lkPathMixin";
window.jQuery = $
export default {
  name: "MainMenu",
  components: {Notification},
  mixins: [lkPathMixin],
  data() {
    return {
      user: JSON.parse(TokenService.getUser())
    }
  },
  methods: {
    logout() {
      TokenService.clear()
      this.user = null
      window.location.href = '/'
    }
  },
  mounted() {
    $('a').on('click', function () {
      if($(this).attr('href') === '#') {
        return false;
      }
    })
  }
}
</script>

<style scoped>

</style>