// @ts-nocheck
export default {
    computed: {
        programName() {
            return this.$route.path.indexOf('forsage') > -1 ? 'Экспортный форсаж' : 'Экспортный Мини-МВА'
        },
        programPath() {
            return this.$route.path.indexOf('forsage') > -1 ? 'forsage' : 'msp'
        },
        programId() {
            return this.$route.path.indexOf('forsage') > -1 ? 1 : 2
        }
    }
}